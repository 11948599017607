import Stories from "react-insta-stories";
import { Story } from "react-insta-stories/dist/interfaces";
import { Box } from "@chakra-ui/react";
import { analytics } from "../../../utils";

interface StoryViewProps {
  stories: Story[];
  width: string | number;
  height: string | number;
  onStoryEnd: () => void;
  onAllStoriesEnd: () => void;
}

const StoryView = ({
  stories,
  width,
  height,
  onStoryEnd,
  onAllStoriesEnd,
}: StoryViewProps) => {
  return (
    <Box position="relative">
      <Stories
        stories={stories}
        width={width}
        height={height}
        onAllStoriesEnd={onAllStoriesEnd}
        onStoryEnd={onStoryEnd}
      />
    </Box>
  );
};

export default StoryView;
