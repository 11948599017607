import { useEffect, useState, useCallback, forwardRef } from "react";
import {
  Text,
  VStack,
  Spinner,
  Button,
  Box,
  Stack,
  HStack,
  Input,
  useBreakpointValue,
  Icon,
  Divider,
  useClipboard,
  useToast,
} from "@chakra-ui/react";
import { KYDEvent as IKYDEvent } from "@common/interfaces/KYDEvent";
import { Wallet } from "@common/interfaces/KYDUser";
import md5 from "md5";
import { analytics, AUTH_STATES, ModalType, showSuccessToast } from "../utils";
import { useNavigate, useParams } from "react-router-dom";
import { FaExternalLinkAlt } from "react-icons/fa";
import { QRCodeSVG } from "qrcode.react";
import { Controller, useForm } from "react-hook-form";
import actions from "../actions";
import { useDialog } from "../Common/Dialog";
import {
  isWaitlistTicketType,
  ticketTypeHasWaitlistSellOrder,
  walletHasWaitlistBuyOrder,
  walletHasWaitlistSellOrder,
} from "./WaitList/utils";
import WaitlistTicketTypes from "./WaitList/WaitlistTicketTypes";
import {
  KYDTicketType,
  KYDWaitlistBuyOrder,
  KYDWaitlistOrder,
  KYDWaitlistSellOrder,
} from "@common/interfaces/KYDTicket";
import { Select } from "chakra-react-select";
import dayjs from "dayjs";

export type FormInputs = {
  first_name: string;
  last_name: string;
  email: string;
  dob: string;
  gender: string;
};

function KYDEventWallet({
  kydEvent,
  authState,
  wallet,
  loading,
  setLoading,
  fetchWallet,
  setModalType,
  onSignIn,
  onLeaveWaitlist,
  onListTickets,
}: {
  kydEvent: IKYDEvent;
  authState: AUTH_STATES;
  wallet: Wallet | null;
  fetchWallet: (event_id: string) => Promise<Wallet>;
  setModalType: (_type: ModalType, _content?: string) => void;
  onSignIn: () => void;
  loading: Record<string, boolean>;
  setLoading: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  onLeaveWaitlist: (
    ticketType: KYDTicketType,
    wo: KYDWaitlistBuyOrder | KYDWaitlistSellOrder
  ) => void;
  onListTickets: () => void;
}) {
  const dialog = useDialog();
  const navigate = useNavigate();
  const { event_id } = useParams();
  const toast = useToast();
  const shareCTA = useBreakpointValue({ base: "Share", md: "Copy Link" });
  const qrCodeSize = useBreakpointValue({
    base: 200,
    md: 260,
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<FormInputs>();

  const { onCopy } = useClipboard(wallet?.share_link || "");

  const rotateQRCode = useCallback((_wallet: Wallet) => {
    const timestamp = new Date().toISOString();
    const formatString = `${_wallet.token.id}/${timestamp}`;
    const checksumSlice = 10;
    const checksum = md5(formatString).slice(0, checksumSlice);
    const withChecksum = `${formatString}/${checksum}/${checksumSlice}`;
    return withChecksum;
  }, []);

  const [qrCodeContent, setQRCodeContent] = useState<string | null>(
    wallet ? rotateQRCode(wallet) : null
  );

  const waitlistTicketTypeIds = kydEvent?.ticket_types
    .filter(isWaitlistTicketType)
    .filter((tt) => !ticketTypeHasWaitlistSellOrder(tt, wallet))
    .map((tt) => tt.id);
  const ownsTicketsElegibleToWaitlist = !!wallet?.tickets.some((t) =>
    waitlistTicketTypeIds.includes(t.ticket_type_id)
  );

  useEffect(() => {
    const isIframe = window.location !== window.parent.location;

    if (isIframe) {
      console.log("will send top post message");
      window.top.postMessage("3DS-authentication-complete", "*");
    }
  }, []);

  useEffect(() => {
    if (wallet) {
      const interval = setInterval(() => {
        const content = rotateQRCode(wallet);
        setQRCodeContent(content);
      }, 5000);

      if (!qrCodeContent) {
        const content = rotateQRCode(wallet);
        setQRCodeContent(content);
      }

      return () => clearInterval(interval);
    }
  }, [wallet, qrCodeContent, rotateQRCode]);

  const onUpdateAttendeeInformation = async (data: any) => {
    analytics.t("wallet: complete registration");
    setLoading((prevLoading: any) => {
      prevLoading.profile = true;
      return prevLoading;
    });
    try {
      await actions.USER.updateProfile({
        ...data,
        gender: data.gender ? data.gender.value : undefined,
      });
      await fetchWallet(event_id!);
    } catch (err) {
      //@ts-ignore
      dialog({
        title: `Error updating profile`,
        text: err.response?.data?.response?.message || err.message,
      });
    }
    setLoading((prevLoading: any) => {
      prevLoading.profile = false;
      return prevLoading;
    });
  };

  const renderAttendeeInformation = ({
    emailRequired,
    genderAndAgeRequired,
  }: {
    emailRequired: boolean;
    genderAndAgeRequired: boolean;
  }) => (
    <VStack w="100%" spacing={4} p={4} bg="gray.100" rounded={"lg"}>
      <Text w="100%" fontSize={"xl"} fontWeight="bold">
        {"Attendee Information"}
      </Text>
      <Stack w="100%">
        {emailRequired ? (
          <Stack w="100%" spacing={4} direction={["column", null, "row"]}>
            <Stack w={["100%", null, "50%"]} spacing={0}>
              <Text fontSize={"sm"}>First Name *</Text>
              <Input
                h="40px"
                {...register("first_name", {
                  required: "First name required",
                })}
                placeholder="First Name"
                bg="white"
              />

              {errors.first_name && (
                <Text fontSize={"xs"} color="red.500">
                  {errors.first_name.message}
                </Text>
              )}
            </Stack>
            <Stack w={["100%", null, "50%"]} spacing={0}>
              <Text fontSize={"sm"}>Last Name *</Text>
              <Input
                h="40px"
                {...register("last_name", {
                  required: "Last name required",
                })}
                placeholder="Last Name"
                bg="white"
              />
              {errors.last_name && (
                <Text fontSize={"xs"} color="red.500">
                  {errors.last_name.message}
                </Text>
              )}
            </Stack>
          </Stack>
        ) : null}
        {genderAndAgeRequired ? (
          <Stack w="100%" spacing={4} direction={["column", null, "row"]}>
            <Stack w={["100%", null, "50%"]} spacing={0}>
              <Text fontSize={"sm"}>{`Gender (required)`}</Text>
              <Controller
                name="gender"
                control={control}
                rules={{
                  required: "Gender is required",
                }}
                render={({ field }) => (
                  <Select
                    {...register("gender")}
                    isSearchable={false}
                    placeholder={"Select one"}
                    menuPortalTarget={document.body}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    chakraStyles={{
                      dropdownIndicator: (provided, state) => ({
                        ...provided,
                        background: "white",
                        w: "20px",
                      }),
                      container: (provided) => ({
                        ...provided,
                        background: "white",
                      }),

                      indicatorSeparator: (provided, state) => ({
                        ...provided,
                        display: "white",
                      }),
                    }}
                    options={[
                      {
                        value: "male",
                        label: "Male",
                      },
                      {
                        value: "female",
                        label: "Female",
                      },
                      {
                        value: "prefernottosay",
                        label: "Other / Prefer Not To Say",
                      },
                    ]}
                  />
                )}
              />
              {errors.gender && (
                <Text fontSize={"xs"} color="red.500">
                  {errors.gender.message}
                </Text>
              )}
            </Stack>
            <Stack w={["100%", null, "50%"]} spacing={0}>
              <Text fontSize={"sm"}>{`Date Of Birth (required)`}</Text>
              <Input
                type="date"
                h="40px"
                {...register("dob", {
                  required: "Date of birth required",
                  validate: {
                    maximumAge: (value) => {
                      if (
                        !value ||
                        !dayjs(value, "YYYY-MM-DD", true).isValid()
                      ) {
                        return "Please enter a valid date";
                      }

                      if (
                        dayjs(value, "YYYY-MM-DD", true).isBefore(
                          dayjs().subtract(120, "year")
                        )
                      ) {
                        return "Please check your date of birth";
                      }
                      return true;
                    },
                  },
                })}
                min={dayjs().subtract(120, "year").format("YYYY-MM-DD")}
                placeholder="Date of birth"
                bg="white"
              />
              {errors.dob && (
                <Text fontSize={"xs"} color="red.500">
                  {errors.dob.message}
                </Text>
              )}
            </Stack>
          </Stack>
        ) : null}
        {emailRequired ? (
          <Stack w="100%" spacing={4} direction={["column", null, "row"]}>
            <Stack w="100%" spacing={0}>
              <Text fontSize={"sm"}>Email *</Text>
              <Input
                h="40px"
                {...register("email", {
                  required: "Email required",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Invalid email format",
                  },
                })}
                placeholder="Email"
                bg="white"
              />
              {errors.email && (
                <Text fontSize={"xs"} color="red.500">
                  {errors.email.message}
                </Text>
              )}
            </Stack>
          </Stack>
        ) : null}
      </Stack>
      <Button
        onClick={handleSubmit(onUpdateAttendeeInformation)}
        variant="kydDark"
        w="100%"
        isDisabled={loading.profile}
        isLoading={loading.profile}
      >
        {emailRequired ? "Next" : "Submit"}
      </Button>
    </VStack>
  );

  /*useEffect(() => {
    if (
      qrCodeContent &&
      (modalType === ModalType.SHOW_WALLET || showTicketsAfterAuth)
    ) {
      setModalType(ModalType.SHOW_WALLET, qrCodeContent || "");
      setShowTicketsAfterAuth(false);
    }
  }, [qrCodeContent, modalType, setModalType, showTicketsAfterAuth]);*/

  const renderTickets = () => (
    <Stack
      flexDir={["column-reverse", null, "row"]}
      bg="gray.100"
      rounded={"lg"}
      w="100%"
      justify={["top", null, "space-between"]}
      align="top"
      wrap={"wrap"}
      p={4}
      spacing={6}
    >
      <Stack mt={{ base: "10px", md: "inherit" }} textAlign={"left"}>
        <Stack spacing={0}>
          <Text color="gray.600" fontWeight={"medium"}>
            {kydEvent.display_mode === "normal" ? "Tickets:" : "RSVPs:"}
          </Text>
          <Stack spacing={0} fontSize={"sm"}>
            {wallet?.summary_items.map((i) => (
              <Text key={i.name}>
                {i.quantity} x {i.name}
              </Text>
            ))}
          </Stack>

          <HStack>
            <a target={"_blank"} rel="noreferrer" href={wallet?.explorer_link}>
              <HStack>
                <Text color="gray.500" fontSize={"xs"}>
                  View On Aptos
                </Text>
                <Icon color="gray.500" as={FaExternalLinkAlt} fontSize="xs" />
              </HStack>
            </a>
          </HStack>
        </Stack>

        {kydEvent.display_mode === "normal" ? (
          <Stack spacing={0}>
            <Text color="gray.600" fontWeight={"medium"}>
              Purchases:
            </Text>
            <Stack spacing={0} fontSize={"sm"}>
              {wallet?.purchase_dates.map((i) => (
                <Stack spacing={0} key={i.date}>
                  <Text>{i.date}</Text>

                  {i.receipt_url ? (
                    <a
                      style={{
                        fontSize: "smaller",
                        textDecoration: "underline",
                      }}
                      href={i.receipt_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Download Receipt
                    </a>
                  ) : null}
                </Stack>
              ))}
            </Stack>
          </Stack>
        ) : null}

        {wallet?.share_link ? (
          <Stack py={4}>
            <Text fontWeight={"bold"} color={"#4022FB"}>
              Share with friends
            </Text>
            <HStack w="100%">
              <Text
                fontSize={"sm"}
                fontWeight={"bold"}
                borderColor={"gray.300"}
                cursor={"pointer"}
                borderBottom={{ base: "0px", md: "1px" }}
                onClick={async () => {
                  analytics.t("event: copied share link", {
                    location: "wallet",
                  });
                  if (window && window.navigator && window.navigator.share) {
                    try {
                      await window.navigator.share({
                        title: kydEvent.name,
                        url: wallet.share_link,
                      });
                      console.log("Data was shared successfully");
                    } catch (err) {
                      //@ts-ignore
                      console.error("Share failed:", err.message);
                    }
                  } else {
                    showSuccessToast(toast, "✅ Link copied!");
                    onCopy();
                  }
                }}
              >
                {wallet.share_link?.split("://").pop()}
              </Text>
              <Text
                borderWidth={{ base: "1px", md: "0px" }}
                borderColor="black"
                bg="none"
                fontSize={"sm"}
                px={2}
                rounded={"md"}
                color={{ base: "black", md: "blue.500" }}
                fontWeight={"bold"}
                cursor={"pointer"}
                onClick={async () => {
                  analytics.t("event: copied share link", {
                    location: "wallet",
                  });
                  if (window && window.navigator && window.navigator.share) {
                    try {
                      await window.navigator.share({
                        title: kydEvent.name,
                        url: wallet.share_link,
                      });
                    } catch (err) {
                      //@ts-ignore
                      console.error("Share failed:", err.message);
                    }
                  } else {
                    showSuccessToast(toast, "✅ Link copied!");
                    onCopy();
                  }
                }}
              >
                {shareCTA}
              </Text>
            </HStack>
          </Stack>
        ) : null}
      </Stack>
      <VStack spacing={4}>
        {qrCodeContent && (
          <Box
            p={6}
            bg="white"
            rounded={"lg"}
            borderWidth={"1px"}
            borderColor={"gray.300"}
          >
            <QRCodeSVG
              value={qrCodeContent}
              size={qrCodeSize}
              mode="L"
              bgColor="rgb(255,255,255)"
              fgColor="rgb(0,0,0)"
              imageSettings={{
                src: "https://content.kydlabs.com/system/crown.png",
                x: undefined,
                y: undefined,
                height: 40,
                width: 40,
                excavate: true,
              }}
            />
          </Box>
        )}
        {kydEvent.display_mode === "normal" ? (
          <>
            <Button
              onClick={() => {
                analytics.t("wallet: clicked transfer tickets");
                setModalType(ModalType.TRANSFER_TICKETS_PHONE);
              }}
              color="white"
              w="full"
              bgGradient={"linear(to-b, #F56565, #ED64A6)"}
              _hover={{ bg: "linear(to-b, #F56565, #ED64A6)" }}
            >
              Transfer Tickets To Friend
            </Button>
            {wallet?.valid_waitlist_ticket_types?.length > 0 ? (
              <VStack w="full">
                <Text fontWeight={"bold"} fontSize={14}>
                  CAN'T GO ANYMORE?
                </Text>
                <Button
                  onClick={() => {
                    analytics.t("wallet: clicked list tickets");
                    onListTickets();
                  }}
                  backgroundColor="lightGreen"
                  borderWidth={2}
                  fontWeight={"bold"}
                  w="full"
                  borderColor="darkGreen"
                  _hover={{ bg: "linear(to-b, #F56565, #ED64A6)" }}
                >
                  LIST YOUR TICKETS
                </Button>
              </VStack>
            ) : null}
          </>
        ) : null}
        <Divider
          display={{ base: "inherit", md: "none" }}
          borderColor={"gray.300"}
        />
      </VStack>
    </Stack>
  );

  const renderWallet = () => {
    if (loading.wallet) {
      return (
        <VStack p={4} w="100%">
          <VStack>
            <Text>Loading tickets...</Text>
            <Spinner />
          </VStack>
        </VStack>
      );
    } else if (loading.wallet_silent) {
      if (wallet?.tickets.length === 0) {
        return (
          <VStack p={4} w="100%">
            <VStack>
              <Text>Loading tickets...</Text>
              <Spinner />
            </VStack>
          </VStack>
        );
      } else {
        return renderTickets();
      }
    } else if (wallet) {
      const genderAndAgeRequired =
        wallet.onboarding_status === "GENDER_AGE_REQUIRED";

      const emailRequired = wallet.onboarding_status === "EMAIL_REQUIRED";
      if (emailRequired || genderAndAgeRequired) {
        return renderAttendeeInformation({
          emailRequired,
          genderAndAgeRequired,
        });
      } else if (wallet.tickets.length > 0) {
        return renderTickets();
      } else {
        return renderNoPurchases();
      }
    }
  };

  const renderNoPurchases = () => (
    <VStack>
      <Text maxW={"400px"}>
        You haven't purchased any tickets yet! Head back to the event page to
        grab your tickets.
      </Text>
      <Button
        maxW={"200px"}
        variant={"kydDark"}
        onClick={() => {
          analytics.t("wallet: go back");
          const searchParams = new URLSearchParams(window.location.search);
          navigate(`/e/${event_id}?${searchParams.toString()}`);
        }}
      >
        Get Tickets
      </Button>
    </VStack>
  );

  const renderSignIn = () => (
    <VStack color={{ base: "white", md: "black" }}>
      <Text>Please sign in to view your tickets 🎟</Text>
      <Button
        w={"100%"}
        onClick={() => {
          analytics.t("wallet: sign in");
          onSignIn();
        }}
        bg={{ base: "white", md: "black" }}
        color={{ base: "black", md: "white" }}
        _hover={{ bg: { base: "gray.100", md: "gray.900" } }}
      >
        Sign In
      </Button>
    </VStack>
  );

  return (
    <VStack w="100%" p={{ base: "2", md: "0" }}>
      {walletHasWaitlistBuyOrder(wallet) ? (
        <WaitlistTicketTypes
          type="buy"
          timezone={kydEvent.timezone}
          wallet={wallet}
          ticketTypes={kydEvent.ticket_types}
          onLeaveWaitlist={onLeaveWaitlist}
          loading={loading}
        />
      ) : null}
      {walletHasWaitlistSellOrder(wallet) ? (
        <WaitlistTicketTypes
          type="sell"
          timezone={kydEvent.timezone}
          wallet={wallet}
          ticketTypes={kydEvent.ticket_types}
          onLeaveWaitlist={onLeaveWaitlist}
          loading={loading}
        />
      ) : null}
      <VStack
        w="100%"
        roundedTop={{
          base: authState === AUTH_STATES.NEEDS_AUTH ? "none" : "lg",
          md: "lg",
        }}
        roundedBottom={{ base: "lg", md: "none" }}
        borderTopWidth={{ base: "1px", md: "none" }}
        borderTopColor={{ base: "white", md: "transparent" }}
        bg={{
          base: authState === AUTH_STATES.NEEDS_AUTH ? "transparent" : "white",
          md: "white",
        }}
        p={4}
      >
        <Stack spacing={1} w="100%">
          <HStack justify={"space-between"}>
            <Text
              color={{
                base: authState === AUTH_STATES.NEEDS_AUTH ? "white" : "black",
                md: "black",
              }}
              textAlign={"left"}
              fontWeight={"bold"}
              fontSize="xl"
            >
              {kydEvent.display_mode === "normal" ? "My Tickets" : "My RSVPs"}
            </Text>
            {wallet && wallet.last_4_phone ? (
              <Text fontSize="sm" color="gray.500">
                Phone: •••{wallet?.last_4_phone}
              </Text>
            ) : null}
          </HStack>

          {wallet &&
          wallet.tickets.length > 0 &&
          (wallet.onboarding_status === "EMAIL_REQUIRED" ||
            wallet.onboarding_status === "GENDER_AGE_REQUIRED") ? (
            <Text
              color={{
                base: authState === AUTH_STATES.NEEDS_AUTH ? "white" : "black",
                md: "black",
              }}
              maxW={["100%", null, "60%"]}
              w="100%"
              textAlign={"left"}
              fontSize="sm"
            >
              One more step! Enter your information below to receive your
              tickets 🎟️
            </Text>
          ) : null}
          {wallet &&
            wallet.tickets.length > 0 &&
            wallet.onboarding_status === "COMPLETE" && (
              <Text
                color={{
                  base:
                    authState === AUTH_STATES.NEEDS_AUTH ? "white" : "black",
                  md: "black",
                }}
                maxW={["100%", null, "60%"]}
                w="100%"
                textAlign={"left"}
                fontSize="sm"
              >
                {kydEvent.display_mode === "normal"
                  ? `Please display the QR code below on your mobile phone for entry into ${
                      kydEvent?.name || " the event"
                    }`
                  : "Thank you for sharing you'll be attending. The QR code below is your RSVP confirmation for the host. This QR code does not guarantee entry."}
              </Text>
            )}
        </Stack>

        {authState === AUTH_STATES.AUTHENTICATED && renderWallet()}

        {authState === AUTH_STATES.NEEDS_AUTH && renderSignIn()}
      </VStack>
    </VStack>
  );
}

export default KYDEventWallet;
